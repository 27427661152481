<template>
  <noscript inline-template>
    <v-layout class="no-js-view">
      <div class="no-js-view-card">
        <div class="no-js-view-content">
          <div class="no-js-view-warning-icon-container">
            <svg
              class="no-js-view-warning-icon"
              style="width: 64px; height: 64px"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </div>

          <div class="no-js-view-title">Javascript Required</div>

          <p class="no-js-view-body">
            We’re sorry, but this app doesn’t work properly without Javascript
            enabled. Please enable it in your browser settings.
          </p>

          <div class="no-js-view-icon-container">
            <a class="no-js-view-link" :href="$route.path"> Reload </a>
            <a
              class="no-js-view-link"
              href="https://enable-javascript.com/"
              target="new"
            >
              Find out how
              <div class="no-js-view-link-icon-container">
                <svg
                  class="no-js-view-link-icon"
                  style="width: 12px; height: 12px"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.583 7L7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </v-layout>
  </noscript>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoJs",
});
</script>

<style lang="scss">
.no-js-view {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 64px;
  background-color: #e6ebf1;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}
.no-js-view-card {
  overflow: hidden;
  max-width: 480px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.no-js-view-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 48px;
}
.no-js-view-warning-icon-container {
  padding-bottom: 16px;
}
.no-js-view-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  white-space: normal;
}
.no-js-view-body,
.no-js-view-title {
  display: block;
  text-align: center;
  color: #697386;
}
.no-js-view-body,
.no-js-view-title {
  display: block;
  text-align: center;
  color: #697386;
}
.no-js-view-body,
.no-js-view-buttonLabel {
  font-size: 14px;
  line-height: 20px;
}
.no-js-view-icon-container {
  display: flex;
  padding-top: 16px;
  justify-content: center;
}
.no-js-view-link {
  display: flex;
  color: #5469d4;
  font-weight: 500;
  text-decoration: none;
}
.no-js-view-link:nth-child(2) {
  margin-left: 20px;
}
.no-js-view-warning-icon {
  fill: #ff6801 !important;
  // fill: #8792a2 !important;
}
.no-js-view-link-icon-container {
  margin-left: 4px;
  transform: translateY(1.07px);
}
.no-js-view-link-icon {
  width: 12px;
  height: 12px;
  fill: #5469d4;
}
.no-js-view-body {
  margin-top: 10px;
}
</style>
