<template>
  <v-dialog :model-value="notAccepted" width="500" persistent>
    <v-card class="py-4 px-2 md:px-4 text-center" rounded="xl">
      <div class="text-h6">Terms of Service</div>
      <p class="mt-4">
        By continuing to the dashboard, you agree to be bound by
        <a :href="links.terms" target="_blank">our terms of service</a>. These
        terms record what is expected of you and what is expected of Orie.
      </p>
      <v-btn
        @click="accept"
        class="mt-4"
        color="primary"
        width="100%"
        :loading="loading"
        depressed
        size="x-large"
        >Accept Terms</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { get } from "lodash";

export default defineComponent({
  setup() {
    const auth = useAuth();
    const { links } = useAppConfig();
    const notAccepted = ref(false);

    const {
      loading,
      mutate: accept,
      onError,
      onDone,
    } = useMutation(gql`
      mutation {
        wsAcceptTerms
      }
    `);

    onError(() => {
      //
    });

    const setResult = (accepted?: boolean) => {
      notAccepted.value = accepted !== true;
    };

    onDone(({ data }: any) => {
      const accepted = get(data, "wsAcceptTerms");
      setResult(accepted);
    });

    onMounted(() => {
      const accepted = auth.context.value?.workspace?.accepted_terms;
      setResult(accepted);
    });

    return {
      accept,
      notAccepted,
      loading,
      links,
    };
  },
});
</script>
